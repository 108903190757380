import { VSF_CURRENCY_COOKIE } from '@vue-storefront/core';
import localizationDefinitions from '../../i18n/localizationDefinitions';
import ciaClient from './cia/client';
import tagManagerClient from './tagManager/client';
import vatValidationClient from './vatValidation/client';
import grahamClient from './graham/client';
import myOrdersClient from './myOrders/client';
import bloomreachClient from './bloomreach/client';
import ctClient from './ct/client';
import { IntegrationCollection } from '~/types/integrations/IntegrationCollection';
import { i18nToLanguageAndCountry } from '~/helpers/locales/i18nCurrentDetails';

export default ({ $axios, $cookies, req, $gtm, i18n, $config }: Context,
  inject: (integrations: string, integrationCollection: IntegrationCollection) => void) => {
  const languageAndCountry = i18nToLanguageAndCountry(i18n);
  const countryLocalization = localizationDefinitions.getByLanguageAndCountry(languageAndCountry);
  const bloomreachDetails = countryLocalization?.getBloomreachDetails(languageAndCountry);
  const $cia = ciaClient($axios, $cookies, req, bloomreachDetails?.key || '', i18n);
  const $tagManager = tagManagerClient($gtm, i18n, $cookies.get(VSF_CURRENCY_COOKIE));
  const $vat = vatValidationClient($axios);
  const $graham = grahamClient($axios);
  const $myOrders = myOrdersClient($axios);
  const $ct = ctClient({ $axios, $config, req });
  const $bloomreach = bloomreachClient({ $axios, $cookies, languageAndCountry, bloomreachDetails });

  const $integrations: IntegrationCollection = {
    $ct,
    $cia,
    $tagManager,
    $vat,
    $graham,
    $myOrders,
    $bloomreach
  };

  inject('integrations', $integrations);
};
