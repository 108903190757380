import CountryLocalization from '../../types/localization/countryLocalization';
import Currency from '../../types/localization/currency';
import currencies from '../../i18n/currencies';
import { defaultCountryCode } from '../../i18n/enums/countryCodes';
import { currencyCodes } from '../../i18n/enums/currencyCodes';
import languageCodes from '../../i18n/enums/languageCodes';
import { getAlgoliaIndexName } from '../../i18n/algoliaLocalization';
import {
  CountryCodeValue,
  VueI18nFormattedLocales,
  VueI18nNumberFormats
} from '~/types/localization/localizationHelperTypes';

class Localization {
  private readonly _countryLocalizations: Array<CountryLocalization> = [];
  private readonly _languageAndCountries: string[] = [];
  private readonly _languages: string[] = [];

  constructor (args: Array<CountryLocalization>) {
    this._countryLocalizations = args;

    for (const countryLocalization of this._countryLocalizations) {
      this._languageAndCountries = [...this._languageAndCountries, ...countryLocalization.getLanguageAndCountries()];
      this._languages = [...countryLocalization.getLanguages(), ...this._languages];
    }
  }

  public getByCountryCode(countryCode: CountryCodeValue | string): CountryLocalization | null {
    for (const countryLocalization of this._countryLocalizations) {
      if (countryLocalization.countryCode?.toLowerCase() === countryCode?.toLowerCase()) {
        return countryLocalization;
      }
    }

    return null;
  }

  public getDomainByCountryCode(countryCode: CountryCodeValue): string | null {
    const countryLocalization = this.getByCountryCode(countryCode);

    if (countryLocalization === undefined || countryLocalization === null) {
      return null;
    }

    return countryLocalization.getDomain();
  }

  public getByDomain(
    domain: string, context: Maybe<Pick<Context, 'env' | 'req'>> = null
  ) : CountryLocalization | null | undefined {
    for (const countryLocalization of this._countryLocalizations) {
      if (
        countryLocalization.getDomain(context) === domain ||
        countryLocalization.prodDomain === domain
      ) {
        return countryLocalization;
      }
    }

    /**
     * If the localization configuration is not found by domain, it probably means that we are
     * running integration testes on the pipeline with localhost. So, get the default country configuration.
     */
    return this._countryLocalizations.find(
      countryLocalization => countryLocalization.countryCode === defaultCountryCode);
  }

  public getByLanguageAndCountry(languageAndCountry: string): CountryLocalization | null {
    for (const countryLocalization of this._countryLocalizations) {
      if (countryLocalization.getLanguageAndCountries().includes(languageAndCountry)) {
        return countryLocalization;
      }
    }

    return null;
  }

  public getAllCountries(): Array<CountryLocalization> {
    return this._countryLocalizations;
  }

  public getAllCurrencies(): Record<ValueOf<typeof currencyCodes>, Currency> {
    return currencies;
  }

  public getVueI18nFormattedLocales(): VueI18nFormattedLocales {
    const formattedLocales: VueI18nFormattedLocales = [];

    for (const countryLocalization of this._countryLocalizations) {
      for (const languageAndCountry of countryLocalization.getLanguageAndCountries()) {
        formattedLocales.push({
          code: languageAndCountry,
          label: languageAndCountry,
          file: languageAndCountry,
          domain: countryLocalization.getDomain(),
          country: countryLocalization.countryCode,
          algoliaIndexName: getAlgoliaIndexName(languageAndCountry, this)
        });
      }
    }

    return formattedLocales;
  }

  public getAllVueI18nFallbacks() {
    let fallbacksMap = {};

    this._countryLocalizations.forEach(countryLocalization => {
      fallbacksMap = {
        ...fallbacksMap,
        ...countryLocalization.getVueI18nFallbacks()
      };
    });

    return fallbacksMap;
  }

  public getVueI18nNumberFormats () : VueI18nNumberFormats {
    const numberFormats : VueI18nNumberFormats = { };

    /**
     * A country might have multiple languages and for vueI18n number formats,
     * we have to inform the currency for each localization. It means that it expects
     * we provide, for example, the currencies for fr-CH, de-CH and it-CH. As we define the
     * currency per country (like CH), here the entries for locale → currency maps are generated automatically
     */
    for (const countryLocalization of this._countryLocalizations) {
      for (const languageAndCountry of countryLocalization.getLanguageAndCountries()) {
        numberFormats[languageAndCountry] = { currency: countryLocalization.currency };
      }
    }

    return numberFormats;
  }

  public getAllLanguageAndCountries() {
    return this._languageAndCountries;
  }

  public getAllLanguages() {
    return this._languages;
  }

  public getCurrencyCodeByCountryCode(countryCode: CountryCodeValue):
  ValueOf<typeof currencyCodes> | undefined {
    return this.getByCountryCode(countryCode)?.currencyCode;
  }

  public getDefaultLanguageByCountryCode(
    countryCode: CountryCodeValue
  ): ValueOf<typeof languageCodes> | undefined {
    return this.getByCountryCode(countryCode)?.getDefaultLanguage();
  }

  public getLanguageAndCountryByCountryCode(
    countryCode: CountryCodeValue,
    languageCode: ValueOf<typeof languageCodes> | null | undefined = null
  ): string {
    return this.getByCountryCode(countryCode)?.getLanguageAndCountry(languageCode) ?? '';
  }
}

export default Localization;
