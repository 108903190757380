import { shallowMount } from '@vue/test-utils';
import RichTextHighlights from '../RichTextHighlights.vue';

describe('RichTextHighlights', () => {
  let wrapper;
  const rows = [
    { richText: 'Markdown Text 1' },
    { richText: 'Markdown Text 2' }
  ];

  beforeEach(() => {
    wrapper = shallowMount(RichTextHighlights, {
      propsData: { rows }
    });
  });

  it('renders correctly', () => {
    expect(wrapper.element).toMatchSnapshot();
  });
});
