import { getAxiosConfig } from '~/helpers/axios/getAxiosConfig';
import { setAxiosInterceptors } from '~/helpers/axios/setAxiosInterceptors';
import createRequest from '~/plugins/integrations/ct/requests/createRequest';
import { extendApiMethods } from '~/additionalServerMiddlewareExtensions/commercetools/extension';
import { getTypedKeys } from '~/helpers/dataStructure/objectOperationsTyped';
import { getMiddlewareUrl } from '~/helpers/env/getMiddlewareUrl';

export const ctBase = 'newCt/';

export default ({
  $config,
  $axios,
  req
}: Pick<Context, '$axios' | '$config' | 'req'>
) => {
  const currentMiddlewareURL = getMiddlewareUrl({
    $config,
    $axios
  });
  const axios = $axios.create(
    getAxiosConfig(`${currentMiddlewareURL}${ctBase}`)
  );

  setAxiosInterceptors({ axios, req, shouldRejectPromise: true });

  const services = {
    axios
  };
  const axiosRequests = {} as
    Record<keyof typeof extendApiMethods, ReturnType<typeof createRequest>>;
  getTypedKeys(extendApiMethods).forEach((request) => {
    axiosRequests[request] = createRequest({ services }, request);
  });

  return {
    services,
    api: axiosRequests
  };
};
