





















import { defineComponent } from '@nuxtjs/composition-api';
import { useUiNavigationStore } from '~/composables';
import NotificationToast from '~/components/atoms/Notification/NotificationToast.vue';

export default defineComponent({
  name: 'Notification',
  components: {
    NotificationToast
  },
  setup () {
    const { uiNotifications } = useUiNavigationStore();

    return {
      uiNotifications
    };
  }
});
