
















import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { RefinementListItem } from 'vue-instantsearch';
import CheckBox from '~/components/atoms/Buttons/CheckBox/CheckBox.vue';
import FacetCount from '~/components/atoms/Search/FacetCount/FacetCount.vue';
import { useAlgolia, useClickFiltersEvent } from '~/composables';
import { FILTERS_INTERACTIONS } from '~/constants/googleTagManager';

export default defineComponent({
  name: 'CheckboxPickerOption',
  components: {
    CheckBox,
    FacetCount
  },
  props: {
    checkboxItem: {
      type: Object as PropType<RefinementListItem>,
      required: true
    },
    refine: {
      type: Function as PropType<(value: string) => void>,
      required: true
    },
    translateAttributes: {
      type: Boolean,
      default: false
    },
    facet: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { getAlgoliaFilterName } = useAlgolia();
    const { callClickFilterEvent } = useClickFiltersEvent();

    const refinementListItemLabel = computed(() =>
      getAlgoliaFilterName(props.checkboxItem.value)
    );

    const handleChange = (item: RefinementListItem) => {
      const filterInteraction =
        props.checkboxItem.isRefined ? FILTERS_INTERACTIONS.FILTER_UNSELECT : FILTERS_INTERACTIONS.FILTER_SELECT;

      callClickFilterEvent({
        filterInteraction,
        filterSection: props.facet,
        filterName: refinementListItemLabel.value.toString(),
        filterValue: item.value
      });
      props.refine(item.value);
    };
    return {
      refinementListItemLabel,
      handleChange
    };
  }
});
