import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ReviewExtendedMutations
} from '~/types/vuex/ReviewExtended';
import getSortParamsFromString from '~/helpers/getSortParamsFromString';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { DEFAULT_SORTING_OPTION } from '~/constants/reviewSortingOptions';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.REVIEW_EXTENDED_MODULE_NAME,
    ReviewExtendedMutations,
    {},
    {}
  >(MODULE_NAMES.REVIEW_EXTENDED_MODULE_NAME);

  store.mutations.setSortParam(getSortParamsFromString(DEFAULT_SORTING_OPTION));

  const sortParam = computed(() => store.state.sortParam);
  return {
    sortParam,
    ...store.getters,
    ...store.mutations,
    ...store.actions
  };
}
