






























import Vue from 'vue';
import { computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { CmsImageResponse } from '~/types/cms/CmsImageResponse';
import { ButtonTextSize } from '~/types/components/Buttons/ButtonTextSize';
import cmsImageToUrl from '~/helpers/cms/cmsImageToUrl';
import { IMAGE_BANNER_DESKTOP_WIDTH, IMAGE_BANNER_MOBILE_WIDTH } from '~/constants/images';
import Button from '~/components/atoms/Buttons/Button/Button.vue';

export default Vue.extend({
  name: 'ImgBanner',
  components: {
    Button
  },
  props: {
    backgroundDesktop: {
      type: Object as PropType<CmsImageResponse>,
      required: true
    },
    backgroundMobile: {
      type: Object as PropType<CmsImageResponse>,
      required: true
    },
    headline: {
      type: String,
      required: true
    },
    strapline: {
      type: String,
      required: true
    },
    callToActionText: {
      type: String,
      required: true
    },
    callToActionSlug: {
      type: String,
      required: true
    }
  },
  setup(props, { root: { $router } }) {
    const imageSourceDesktop = computed(() => cmsImageToUrl(props.backgroundDesktop as CmsImageResponse));
    const imageSourceMobile = computed(() => cmsImageToUrl(props.backgroundMobile as CmsImageResponse));
    const openCallToActionLink = () => {
      $router.push(props.callToActionSlug);
    };
    return {
      imageSourceDesktop,
      imageSourceMobile,
      ButtonTextSize,
      openCallToActionLink,
      IMAGE_BANNER_DESKTOP_WIDTH,
      IMAGE_BANNER_MOBILE_WIDTH
    };
  }
});
