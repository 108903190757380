
/**
* @param {Context[req]?} request
* @returns {string}
*/
function getHostname(request) {
  return request?.headers['x-forwarded-host'] || request?.headers.host || window?.location?.host;
}

module.exports = {
  getHostname
};
