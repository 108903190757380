import { UseReviewData, RateCount, Review } from '~/utils/ct';

function getItems(review: UseReviewData): Review[] {
  return review?.results ?? [];
}

function getReviewId(item: Review): string {
  return item?.id ?? '';
}

function getReviewAuthor(item: Review): string {
  return item?.authorName ?? '';
}

function getReviewMessage(item: Review): string {
  return item?.text ?? '';
}

function getReviewRating(item: Review): number {
  return item?.rating ?? 0;
}

function getReviewDate(item: Review, acceptLanguage: string): string {
  const createdAt = item?.createdAt;
  if (!createdAt) {
    return '';
  }
  const date = new Date(createdAt);
  return new Intl.DateTimeFormat(acceptLanguage).format(date);
}

function getTotalReviews(review: UseReviewData): number {
  return review?.total ?? 0;
}

function getAverageRating(review: UseReviewData): number {
  return review?.averageRating ?? 0;
}

function getRatesCount(review: UseReviewData): RateCount[] {
  const rates = review?.ratingsDistribution || {};
  return Object.entries(rates)
    .map(([rate, count]) => ({
      rate: Number(rate),
      count: Number(count)
    }));
}

function getReviewsPage(review: UseReviewData): number {
  const limit = review?.limit || 0;
  const offset = review?.offset || 0;

  if (!limit) {
    return 1;
  }
  return (offset / limit) + 1;
}

export const reviewGetters = {
  getItems,
  getReviewId,
  getReviewAuthor,
  getReviewMessage,
  getReviewRating,
  getReviewDate,
  getTotalReviews,
  getAverageRating,
  getRatesCount,
  getReviewsPage
};
