import { ONSALE_PRODUCT_GRAPHQL_CUSTOM_FILTER, BWARE_PRODUCT_GRAPHQL_CUSTOM_FILTER } from '~/constants/commerceTools';
import { PRODUCT_ZONE_FILTER } from '~/constants/productZone';
import type { ProductZoneFilter } from '~/constants/productZone';
import { getLocalizedFilter } from '~/helpers/commercetools/graphql/getLocalizedFilter';

export const getProductZoneFilter = (
  filterType: ProductZoneFilter,
  languageAndCountry: string,
  countryCode: string): string => {
  const filterMap = {
    [PRODUCT_ZONE_FILTER.ON_SALE]: getLocalizedFilter(
      ONSALE_PRODUCT_GRAPHQL_CUSTOM_FILTER,
      languageAndCountry,
      countryCode
    ),
    [PRODUCT_ZONE_FILTER.BWARE]: BWARE_PRODUCT_GRAPHQL_CUSTOM_FILTER
  };
  return filterMap[filterType];
};
