import { isSeparateMiddleware } from '~/helpers/env/checkEnvironmentType';
export const getMiddlewareUrl = ({
  $config: { ssrMiddlewareUrl, middlewareUrl },
  $axios
}: Pick<Context, '$axios' | '$config'>) => {
  if (!process.server) {
    return middlewareUrl;
  } else if (!isSeparateMiddleware()) {
    const middlewareUrlNoDashes = middlewareUrl.replaceAll('/', '');
    return `${$axios.defaults.baseURL}${middlewareUrlNoDashes}/`;
  }
  return ssrMiddlewareUrl || middlewareUrl;
};
