import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ExtraGuaranteeSidebarMutations
} from '~/types/vuex/ExtraGuaranteeSidebar';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.EXTRA_GUARANTEE_SIDEBAR_MODULE_NAME,
    ExtraGuaranteeSidebarMutations,
    {},
    {}
  >(MODULE_NAMES.EXTRA_GUARANTEE_SIDEBAR_MODULE_NAME);
  const isExtraGuaranteeOpen = computed(() => store.state.isExtraGuaranteeOpen);
  return {
    isExtraGuaranteeOpen,
    ...store.mutations
  };
}
