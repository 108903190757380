import { computed } from '@nuxtjs/composition-api';
import { CATEGORY_TREE } from '~/constants/categoryTree';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { sortCategories } from '~/helpers/category/sortCategories';
import { useCategory } from '~/utils/ct';

export const useCategoryExtended = () => {
  const {
    categories: firstLevelCategoriesContainerRaw,
    search: searchFirstLevelCategories
  } = useCategory('navigation-first-level-categories');

  const loadFirstLevelCategories = async (acceptLanguage = '') => {
    await searchFirstLevelCategories({
      key: CATEGORY_TREE.DEFAULT.key,
      acceptLanguage,
      customQuery: { categories: CUSTOM_QUERIES.CATEGORIES.NAVIGATION }
    });
  };

  const firstLevelCategoriesContainer = computed(() => {
    firstLevelCategoriesContainerRaw.value?.forEach((rootCategory) => {
      sortCategories(rootCategory.children ?? []);
    });
    return firstLevelCategoriesContainerRaw.value || [];
  });

  return {
    loadFirstLevelCategories,
    firstLevelCategoriesContainer
  };
};
