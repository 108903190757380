const core = require('@vue-storefront/core');
const updateCartMutation = require('../../queries/cart/updateCart');
const cartWebSocketHelper = require('../../../../helpers/webSocket.js');
const { isWebSocketEnabledForCountry } = require('../../../../helpers/locales/isWebSocketEnabledForCountry');
const getStoreKey = require('../getStoreKey');
const { clientMutate } = require('../queries');
const { validateAddresses } = require('../validateAddresses');

/**
 * Updates user cart using provided actions
 *
 * @param {Context} context
 * @param params - Cart and update actions information
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
module.exports.updateCartBase = async (context, params, customQuery) => {
  const { locale, acceptLanguage, currency, store, country } = context.config;

  // Prepare variables for the GraphQL query
  const userVariables = params ? { locale, currency, ...params } : {};
  validateAddresses(userVariables.actions);
  const defaultVariables = { ...userVariables, acceptLanguage, ...getStoreKey(store) };

  const { updateCart: updateCartGql } = context.extendQuery(customQuery, {
    updateCart: {
      query: updateCartMutation,
      variables: defaultVariables
    }
  });

  try {
    const response = await clientMutate(context, {
      mutation: updateCartGql.query,
      variables: updateCartGql.variables
    });
    const cart = response.data.cart;
    if (isWebSocketEnabledForCountry(country)) {
      cartWebSocketHelper.sendCartUpdate({
        data: response.data,
        cartId: cart.id
      });
    }

    return response;
  } catch (error) {
    const VERSION_MISMATCH_CODE = 'ConcurrentModification';
    const canRetry = params?.versionFallback ?? true;

    const causedByMismatch = error.graphQLErrors?.[0]?.extensions?.code?.includes(VERSION_MISMATCH_CODE);
    const currentVersion = error.graphQLErrors?.[0]?.extensions?.currentVersion;

    if (!causedByMismatch || !canRetry || !currentVersion) {
      throw error;
    }
    core.Logger.debug(`Cart version mismatch. Retrying with version: ${currentVersion}`);
    return this.updateCartBase(context, { ...params, version: currentVersion }, customQuery);
  }
};
