import { useVSFContext } from '@vue-storefront/core';
import { IntegrationCollection } from '~/types/integrations/IntegrationCollection';
import { getCt } from '~/helpers/getCt';

export const useIntegrations = <T extends boolean>({ vsfCt }: { vsfCt?: T } = {}): IntegrationCollection<T> => {
  const context = useVSFContext();
  const { $integrations } = context;

  return {
    $ct: getCt(context, { vsfCt }),
    $tagManager: $integrations.$tagManager,
    $bloomreach: $integrations.$bloomreach,
    $cia: $integrations.$cia,
    $vat: $integrations.$vat,
    $graham: $integrations.$graham,
    $myOrders: $integrations.$myOrders
  };
};
