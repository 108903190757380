import type { Customer } from '@vsf-enterprise/commercetools-types';

function getFirstName(user: Customer | null): string {
  return user?.firstName || '';
}

function getLastName(user: Customer | null): string {
  return user?.lastName || '';
}

function getFullName(user: Customer | null): string {
  return user ? `${user.firstName} ${user.lastName}` : '';
}

function getEmailAddress(user: Customer | null): string {
  return user?.email || '';
}

function getDateOfBirth(user: Customer | null): string {
  return user?.dateOfBirth || '';
}

export const userGetters = {
  getFirstName,
  getLastName,
  getFullName,
  getEmailAddress,
  getDateOfBirth
};
