





















import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import RelatedProducts from '~/components/organisms/Product/RelatedProducts/RelatedProducts.vue';
import { useI18n, useProductRelated } from '~/composables';
import { getProductZoneFilter } from '~/helpers/commercetools/graphql/getProductZoneFilter';
import { PRODUCT_ZONE_FILTER } from '~/constants/productZone';
import type { ProductZoneFilter } from '~/constants/productZone';
import { PRODUCT_CARD_SIZE } from '~/constants/productCardSize';

export default defineComponent({
  name: 'ProductZoneBox',
  components: {
    RelatedProducts
  },
  props: {
    header: {
      type: String,
      default: ''
    },
    headerClass: {
      type: String,
      default: 'text-EXPONDO-black'
    },
    linkText: {
      type: String,
      default: null
    },
    linkUrl: {
      type: String,
      default: null
    },
    linkClass: {
      type: String,
      default: 'text-EXPONDO-secondary'
    },
    productFilter: {
      type: String as PropType<ProductZoneFilter>,
      required: true,
      validator: (value: ProductZoneFilter) => {
        return Object.values(PRODUCT_ZONE_FILTER).includes(value);
      }
    }
  },
  setup(props) {
    const { languageAndCountry, countryCode } = useI18n();

    const { search: searchRelatedProducts, productsInStock: relatedProducts, loading } =
      useProductRelated(`product-zone-${props.productFilter}`);

    const productCustomFilter = computed(() => {
      return getProductZoneFilter(props.productFilter, languageAndCountry.value, countryCode.value.toUpperCase());
    });

    onSSR(async () => {
      const searchParams = {
        limit: 8,
        customFilters: productCustomFilter.value
      };
      await searchRelatedProducts(searchParams);
    });

    return {
      relatedProducts,
      loading,
      PRODUCT_CARD_SIZE
    };
  }
});

