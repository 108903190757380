import { computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ShippingAddressTypeMutations
} from '~/types/vuex/ShippingAddressType';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { ShippingAddressType } from '~/types/checkout/ShippingAddressType';
import { shippingAddressTypeCookie } from '~/constants/cookies';

export default function () {
  const { $cookies } = useVSFContext();
  const store = defineModule<
    typeof MODULE_NAMES.SHIPPING_ADDRESS_TYPE_MODULE_NAME,
    ShippingAddressTypeMutations,
    {},
    {}
  >(MODULE_NAMES.SHIPPING_ADDRESS_TYPE_MODULE_NAME);

  const shippingAddressCookie = $cookies.get(shippingAddressTypeCookie);
  if (shippingAddressCookie) {
    store.mutations.setShippingAddressType(shippingAddressCookie);
  } else {
    store.mutations.setShippingAddressType(ShippingAddressType.SAME_AS_BILLING);
  }

  const shippingAddressType = computed(() => store.state.shippingAddressType);

  const setShippingAddressTypeMutation = (shippingAddressType: ValueOf<typeof ShippingAddressType>) => {
    store.mutations.setShippingAddressType(shippingAddressType);
  };

  return {
    shippingAddressType,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
    setShippingAddressTypeMutation
  };
}
