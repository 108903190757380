const { validatePostalCode } = require('../../../../helpers/validatePostalCode');

const validateAddress = (address) => {
  return validatePostalCode(address.country, address.postalCode);
};

module.exports.validateAddresses = (requestActions) => {
  requestActions.forEach(action => {
    if (action.setBillingAddress && !validateAddress(action.setBillingAddress.address)) {
      throw new Error('Postal code is incorrect in billing address');
    }
    if (action.setShippingAddress && !validateAddress(action.setShippingAddress.address)) {
      throw new Error('Postal code is incorrect in shipping address');
    }
  });
};
