import type { ComputedRef, Ref } from '@nuxtjs/composition-api';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import type { UpdatePaginationPageParams } from '~/types/pagination';
import { excludeDeactivatedReviews } from '~/helpers/commercetools/graphql/excludeDeactivatedReviews';
import { DEFAULT_REVIEWS_SEARCH_PARAMS } from '~/constants/pagination';
import { useReview } from '~/utils/ct';
import { useReviewExtendedStore } from '~/composables';

export const useReviewExtended = (productId: ComputedRef<string> | Ref<string>) => {
  const { sortParam, setSortParam } = useReviewExtendedStore();

  const {
    reviews,
    search: searchReviews
  } = useReview('productReviews');

  const updateSortParams = (param:string[]) => {
    setSortParam(param);
  };

  const search = async ({ limit, offset }: UpdatePaginationPageParams = DEFAULT_REVIEWS_SEARCH_PARAMS) => {
    await searchReviews({
      limit,
      offset,
      sort: sortParam.value,
      customQuery: CUSTOM_QUERIES.REVIEWS,
      where: excludeDeactivatedReviews(productId.value)
    });
  };

  return {
    reviews,
    updateSortParams,
    search
  };
};
