import { sha256 } from 'js-sha256';
import { cartGetters } from '@vsf-enterprise/commercetools';
import { productGetters } from '~/utils/ct';
import { PayNowTags } from '~/plugins/integrations/tagManager/helpers/PayNowTags';
import { prepareProductTags } from '~/plugins/integrations/tagManager/helpers/transformers';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { getShippingPrice, getTotalTaxPrice } from '~/helpers/cart/getTotalPrices';
import { MappedDiscount } from '~/types/discounts';
import { getFirstDiscountCode } from '~/helpers/cart/cartDiscountGetter';
import { getTotalGrossPrice } from '~/helpers/cart/getTotalPricesModule';
import { TriggerPayNowParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerPayNowTagsWrapper = ($gtm: Gtm, languageAndCountry: string, currency: string) => {
  return (params: TriggerPayNowParameters) => {
    const { cart, products, paymentMethod, paymentType, purchaseUserInfo } = params;
    const coupon: MappedDiscount['code'] = getFirstDiscountCode(cart);
    const payNowTags = new PayNowTags();
    if (products?.length) {
      const productTags = products.map(product => {
        return prepareProductTags(
          product,
          {
            currency,
            quantity: cart?.lineItems?.find(item =>
              cartGetters.getItemSku(item) === productGetters.getSku(product))?.quantity,
            languageAndCountry
          });
      });
      payNowTags.assignProductValues(productTags);
    }
    payNowTags.assignOrderInformation({
      value: getTotalGrossPrice(cart),
      tax: getTotalTaxPrice(cart),
      shipping: getShippingPrice(cart),
      payment_method: paymentMethod,
      payment_type: paymentType,
      currency,
      coupon
    });

    if (purchaseUserInfo !== undefined) {
      const { email, phone } = purchaseUserInfo;
      const hashedEmail = email ? sha256(email) : undefined;
      const hashedPhone = phone ? sha256(phone) : undefined;

      payNowTags.assignAttributes({
        customer_email: email,
        customer_hashed_email: hashedEmail,
        customer_hashed_phone: hashedPhone,
        // for lt_transactions, ltv, account_age and account_created_date we're abandoning it for now - we'll get back to it after delivering account management
        lt_transactions: undefined,
        ltv: undefined,
        account_age: undefined,
        account_created_date: undefined,
        crm_status: 0
      });
    }

    $gtm.push(payNowTags);
  };
};
