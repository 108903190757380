import { ClickAlternativeProduct } from '~/plugins/integrations/tagManager/helpers/ClickAlternativeProduct';
import { A_WARE_PRODUCT_CLICK, B_WARE_PRODUCT_CLICK } from '~/constants/googleTagManager';
import { Gtm } from '~/types/@nuxtjs/gtm';

export const triggerClickAlternativeProductWrapper = ($gtm: Gtm) => {
  return (isAware: boolean, productSku: string) => {
    const clickAlternativeProduct = new ClickAlternativeProduct(
      isAware ? A_WARE_PRODUCT_CLICK : B_WARE_PRODUCT_CLICK,
      productSku
    );
    $gtm.push({ ecommerce: null });
    $gtm.push(clickAlternativeProduct);
  };
};
