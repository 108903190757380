/* eslint-disable camelcase */
import { CLEAR_ONE_PARAMETER } from '~/constants/googleTagManager';

export class ClearSingleFiltersParameter {
  event = CLEAR_ONE_PARAMETER;
  event_info: {
    filter_name: string,
    filter_value: string | number,
    category_path: string,
    category_name: string,
    listing_type: string
  };

  constructor({
    filterName,
    filterValue,
    categoryPath,
    categoryName,
    listingType
  }: {
    filterName: string,
    filterValue: string | number,
    categoryPath: string,
    categoryName: string,
    listingType: string
  }) {
    this.event_info = {
      filter_name: filterName,
      filter_value: filterValue,
      category_path: categoryPath,
      category_name: categoryName,
      listing_type: listingType
    };
  }
}
