import { mapPaginationParams } from '~/utils/ct/composables/helpers/apiHelpers';
import { ApiParams } from '~/utils/ct';
import { useIntegrations } from '~/composables';

export function useProductApi() {
  const { $ct } = useIntegrations();

  const search = ({ customQuery, ...searchParams }: ApiParams) => {
    const apiSearchParams = {
      ...searchParams,
      ...mapPaginationParams(searchParams)
    };
    return $ct.api.getProduct(apiSearchParams, customQuery);
  };

  return {
    search
  };
}
