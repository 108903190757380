const { gql } = require('graphql-tag');

const DefaultLineItem = require('../../fragments/defaultLineItem').fragment;
const DefaultAddress = require('../../fragments/defaultAddress').fragment;
const DefaultMoney = require('../../fragments/money/defaultMoney').fragment;
const MinimalCustomer = require('../../fragments/customer/minimalCustomer').fragment;
const DefaultDiscountCodeInfo = require('../../fragments/discountCode/defaultDiscountCodeInfo').fragment;
const DefaultTaxedPrice = require('../../fragments/price/defaultTaxedPrice').fragment;
const DefaultCustomLineItem = require('../../fragments/defaultCustomLineItem').fragment;

module.exports = {
  fragment: gql`
    ${DefaultAddress}
    ${DefaultLineItem}
    ${DefaultCustomLineItem}
    ${DefaultMoney}
    ${MinimalCustomer}
    ${DefaultDiscountCodeInfo}
    ${DefaultTaxedPrice}

    fragment ThankYouPageOrder on Order {
      lineItems {
        ...DefaultLineItem
      }
      customLineItems {
        ...DefaultCustomLineItem
      }
      discountCodes {
        ...DefaultDiscountCodeInfo
      }
      totalPrice {
        ...DefaultMoney
      }
      taxedPrice {
        ...DefaultTaxedPrice
      }
      shippingInfo {
        price {
          ...DefaultMoney
        }
        taxedPrice {
          totalGross {
            centAmount
          }
        }
      }
      orderState
      id
      orderNumber
      version
      createdAt
      customerEmail
      customer {
        ...MinimalCustomer
      }
      shippingAddress {
        ...DefaultAddress
      }
      billingAddress {
        ...DefaultAddress
      }
      cart {
        id
        version
      }
      paymentInfo {
        payments {
          id
          paymentMethodInfo {
            paymentInterface
            name(locale: "en")
          }
          custom {
            customFieldsRaw {
              name
              value
            }
          }
        }
      }
    }
  `
};
