/**
 * @param { string } environmental
 * @param { Pick<Context, 'env' | 'req'> | undefined } context
 * @returns { string }
 */
const getEnvironmental = (environmental, context = undefined) => {
  return process.env[environmental] ?? context?.env?.[environmental] ?? context?.$config?.[environmental];
};

module.exports = {
  getEnvironmental
};
