import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  PaginationMutations
} from '~/types/vuex/Pagination';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.PAGINATION_MODULE_NAME,
    PaginationMutations,
    {},
    {}
  >(MODULE_NAMES.PAGINATION_MODULE_NAME);

  const currentPage = computed(() => store.state.currentPage);

  return {
    currentPage,
    ...store.mutations
  };
}
