import {
  useIntegrations
} from '~/composables';
export default function () {
  const { $tagManager } = useIntegrations();
  const callAddToCart = (sku: string) => {
    $tagManager.events.triggerAddToCartBundleBuilder({ sku });
  };
  const callClickBundleItem = (sku: string) => {
    $tagManager.events.triggerClickItemBundleBuilder({ sku });
  };
  const callViewEvent = (sku: string) => {
    $tagManager.events.triggerViewBundleBuilder({ sku });
  };
  return { callAddToCart, callClickBundleItem, callViewEvent };
}
