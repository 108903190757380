

















import { computed, defineComponent, toRefs, ref, onMounted } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { productGetters } from '~/utils/ct';
import { LinkType } from '~/types/components/Link/LinkType';
import ProductCardTile from '~/components/molecules/Product/ProductCardTile/ProductCardTile.vue';
import { SIMPLIFIED_CARD } from '~/constants/productCard';
import { ENERGY_RATING_SIZE } from '~/constants/energyRating';
import {
  useProductPrice,
  useCartExtended,
  useI18n,
  useBundleBuilderEvents,
  useUiState
} from '~/composables';
import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { getEnergyRating } from '~/helpers/product/energyRating';
import { getLineItemFromSku } from '~/helpers/cart/getLineItemFromSku';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import { extractLocalisedAttributes } from '~/helpers/product/extractLocalisedAttributes';
import { getFormattedPrice } from '~/helpers/product/getFormattedPrice';

export default defineComponent({
  name: 'ComplementaryProductCart',
  components: {
    ProductCardTile
  },
  props: {
    product: {
      type: Object as PropType<ProductVariantExtended>,
      required: true
    },
    isAlwaysChecked: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { cart, addItem, updateQuantity } = useCartExtended();
    const { callAddToCart } = useBundleBuilderEvents();
    const { isComplementarySidebarOpen, toggleComplementarySidebar } = useUiState();

    const { languageAndCountry } = useI18n();
    const { getPriceInformation } = useProductPrice();

    const { product } = toRefs(props);
    const attributesLocalized = ref(null);

    const cardProduct = computed(() => {
      const { productPrice, discountedPrice } =
        getPriceInformation(product, attributesLocalized);
      const name = product.value?._name;
      const image = product.value?.images?.[0]?.url;
      const price = productPrice.value;
      const link = `/${product.value._slug}`;
      const reviewsCount = productGetters.getTotalReviews(product.value);
      const rating = productGetters.getAverageRating(product.value);

      return {
        name,
        image,
        price,
        discountedPrice: discountedPrice.value,
        link,
        rating,
        reviewsCount
      };
    });

    const energyRating = computed(() => {
      return getEnergyRating({ product: product.value });
    });

    const editableIsChecked = ref(false);
    const lineItem = computed(() => getLineItemFromSku(cart.value, props.product.sku));
    const handleCheckBoxClick = async () => {
      if (props.isAlwaysChecked) return;

      const productSku = props.product?.sku;

      const price = +getFormattedPrice(props.product);
      editableIsChecked.value = !editableIsChecked.value;
      if (editableIsChecked.value) {
        await addItem({
          product: props.product,
          quantity: 1
        });
        emit('product-selection-changed', price);
        productSku && callAddToCart(productSku);
      } else if (lineItem.value) {
        await updateQuantity(lineItem.value, lineItem.value.quantity - 1);
        emit('product-selection-changed', -price);
      }
    };

    const hideComplementarySidebar = () => {
      isComplementarySidebarOpen.value && toggleComplementarySidebar();
    };

    const isChecked = computed(() => props.isAlwaysChecked || editableIsChecked.value);

    onMounted(() => {
      attributesLocalized.value = extractLocalisedAttributes(product.value,
        [PRODUCT_ATTRIBUTES.STANDARD_PRICE],
        languageAndCountry.value);
    });

    return {
      isChecked,
      handleCheckBoxClick,
      LinkType,
      SIMPLIFIED_CARD,
      ENERGY_RATING_SIZE,
      cardProduct,
      energyRating,
      editableIsChecked,
      hideComplementarySidebar
    };
  }
});
