





















import { computed, defineComponent, onBeforeMount } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import RelatedProducts from '~/components/organisms/Product/RelatedProducts/RelatedProducts.vue';
import { useProductRelated } from '~/composables';
import { limitByKeysCustomerFilter } from '~/helpers/commercetools/graphql/limitByKeysCustomerFilter';
import extractBestsellerValidSKUs from '~/helpers/cms/extractBestsellerValidSKUs';
import { RelatedBanner as RelatedBannerType } from '~/types/components/RelatedBanner/RelatedBanner';

export default defineComponent({
  name: 'HomeProducts',
  components: {
    RelatedProducts
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    commaSeparatedSkus: {
      type: String,
      default: ''
    },
    seeAllLinkText: {
      type: String,
      default: null
    },
    seeAllLinkSlug: {
      type: String,
      default: null
    },
    hasBanner: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    banner: {
      type: Object as PropType<RelatedBannerType>,
      default: () => {
        return {};
      }
    },
    hasControlsOutsideContainer: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const skuListCacheId = 'bestsellerProducts' + props.commaSeparatedSkus
      .replace(/,/g, '')
      .replace(/ /g, '');
    const {
      productsInStock: bestsellerProductsBasedOnSkuList,
      search: searchProductsBasedOnSkus,
      loading
    } = useProductRelated(skuListCacheId);

    const homeProductsTitle = computed(() => {
      return props.title || props.banner.header;
    });
    const homeProductsDescription = computed(() => {
      return props.banner.description;
    });

    onBeforeMount(async () => {
      const extractionResult = extractBestsellerValidSKUs(props.commaSeparatedSkus);

      if (extractionResult.skus.length > 0) {
        const searchParams = {
          customFilters: limitByKeysCustomerFilter(extractionResult.skus)
        };
        await searchProductsBasedOnSkus(searchParams);
      }

      extractionResult.warnings.forEach(warning => {
        console.warn(warning);
      });
    });

    return {
      bestsellerProductsBasedOnSkuList,
      loading,
      homeProductsTitle,
      homeProductsDescription
    };
  }
});

