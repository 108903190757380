const axios = require('axios').default;
const { isEligibleForCashPayment } = require('../../helpers/Checkout/cashPaymentHelpers');
const { fetchMyCart } = require('../commercetools/helpers/queries');
const { getVolumeDiscounts } = require('../commercetools/helpers/getVolumeDiscounts');
const { addToCart } = require('../commercetools/helpers/addToCart');
const { removeFromCart } = require('../commercetools/helpers/removeFromCart');
const { updateCart } = require('../commercetools/helpers/updateCart');
const { deleteCart } = require('../commercetools/helpers/deleteCart');
const { updateCartQuantity } = require('../commercetools/helpers/updateCartQuantity');
const { createMyOrderFromCart } = require('../commercetools/helpers/createMyOrderFromCart');
const CUSTOM_PAYMENT = require('../../constants/customPayment');
const cartwebSocketHelper = require('../../helpers/webSocket');
const { createCustomPayment } = require('./helpers/createCustomPayment');
const CATALOG_SORTS_CT_MAP = require('./../../constants/catalogSortsCtMap');
const createCartMutation = require('./queries/cart/createCartWithInventoryModeQuery');
const inventoryQuery = require('./queries/inventory/getInventoryEntriesQuery');
const basicProfileQuery = require('./queries/user/getMe/basicProfile');
const fullProfileQuery = require('./queries/user/getMe/fullProfile');
const customerSignMeInQuery = require('./queries/user/customerSignMeInQuery');
const shippingMethodsQuery = require('./queries/shipping/shippingMethodsQuery');
const customerSignMeUpQuery = require('./queries/user/customerSignMeUpQuery');
const customerUpdateMeQuery = require('./queries/user/customerUpdateMeQuery');
const getCustomerUpdateActions = require('./helpers/getCustomerUpdateActions');
const commercetoolsUrl = process.env.CT_API_HOST + '/' + process.env.CT_PROJECT_KEY;
const productProjectionsSearchUrl = 'product-projections/search';
const { buildCategoryWhere } = require('./helpers/search');
const categoriesListingQuery = require('./queries/category/categoriesListingQuery');
const getProduct = require('./helpers/product/getProduct');
const getOrders = require('./helpers/order/getOrders');
const getComplementaryProductsBySku = require('./helpers/product/getComplementaryProductsBySku');
const { clientQuery, clientMutate } = require('./helpers/queries');
const { getDefaultCart } = require('./helpers/getDefaultCart');
const { CUSTOM_QUERIES } = require('#~/constants/customQueries.js');

module.exports = {
  name: 'commercetools-api-extension',
  extendApiMethods: {
    getProductProjectionSearch: async (context, params) => {
      const { locale } = context.config;
      // TODO: INSP-347 handle filters
      const queryData = {
        'filter.query': params.filterQuery,
        limit: params.limit,
        offset: params.offset,
        sort: CATALOG_SORTS_CT_MAP[params.sort] ?? params.sort,
        localeProjection: locale,
        filters: params.filters
      };

      const request = await axios.get(
        commercetoolsUrl + '/' + productProjectionsSearchUrl + '?' + new URLSearchParams(queryData).toString(),
        {
          headers: { Authorization: `Bearer ${context.config.auth.onTokenRead().access_token}` }
        });
      return request.data;
    },
    getInventory: async (context, params, customQuery) => {
      const defaultVariables = {
        where: Array.isArray(params.sku)
          ? `sku in ( ${params.sku.map(element => `"${element}"`).toString()} )`
          : `sku="${params.sku}"`
      };

      const { inventoryEntries } = context.extendQuery(
        customQuery, {
          inventoryEntries: {
            query: inventoryQuery,
            variables: defaultVariables
          }
        }
      );
      const response = await clientQuery(context, {
        query: inventoryEntries.query,
        variables: inventoryEntries.variables,
        fetchPolicy: 'no-cache'
      });
      cartwebSocketHelper.sendUpdatedStock({ data: response.data.inventoryEntries, cartId: params.cartId });
      return response;
    },
    createCart: async (context, cartDraft, customQuery) => {
      const { acceptLanguage, currency, country, store, locale } = context.config;
      const defaultVariables = {
        acceptLanguage,
        locale,
        currency,
        storeKey: store,
        draft: {
          inventoryMode: 'TrackOnly',
          locale,
          currency,
          country,
          ...getDefaultCart(country),
          ...cartDraft
        }
      };
      const { createCart: createCartGql } = context.extendQuery(
        customQuery, { createCart: { query: createCartMutation, variables: defaultVariables } }
      );

      return await clientMutate(context, {
        mutation: createCartGql.query,
        variables: createCartGql.variables,
        fetchPolicy: 'no-cache'
      });
    },
    addToCart,
    removeFromCart,
    updateCartQuantity,
    updateCart,
    createMyOrderFromCart,
    deleteCart,
    createCodPayment: async (context,
      { cartId, country },
      customQuery = undefined) => {
      const cart = await fetchMyCart(context, cartId);

      if (isEligibleForCashPayment(cart, country)) {
        return createCustomPayment(cart, context, customQuery, CUSTOM_PAYMENT.COD);
      }
    },
    createBankPayment: async (context,
      { cartId },
      customQuery = undefined) => {
      const cart = await fetchMyCart(context, cartId);

      return createCustomPayment(cart, context, customQuery, CUSTOM_PAYMENT.BANK_TRANSFER);
    },
    customerSignMeIn: (context, draft) => {
      const { locale, acceptLanguage, currency } = context.config;

      return clientMutate(context, {
        mutation: customerSignMeInQuery,
        variables: {
          draft,
          locale,
          acceptLanguage,
          currency
        },
        fetchPolicy: 'no-cache'
      });
    },
    /**
     * @param {import('@nuxt/types').Context} context
     * @param params
     * @param customQuery
     */
    getMe: async (context, params = {}, customQuery) => {
      const { locale, acceptLanguage, currency } = context.config;
      const { customer } = params;

      const defaultVariables = {
        locale,
        acceptLanguage,
        currency
      };

      const { getBasicProfile, getFullProfile } = context.extendQuery(customQuery, {
        getBasicProfile: { query: basicProfileQuery, variables: defaultVariables },
        getFullProfile: { query: fullProfileQuery, variables: defaultVariables }
      });

      const profile = customer ? getFullProfile : getBasicProfile;
      return await clientQuery(context, {
        query: profile.query,
        variables: profile.variables,
        fetchPolicy: 'no-cache'
      });
    },
    /**
     * @param {import('@nuxt/types').Context} context
     * @param draft
     */
    customerSignMeUp: async (context, draft) => {
      const { locale, acceptLanguage, currency, country } = context.config;
      return await clientMutate(context, {
        mutation: customerSignMeUpQuery,
        variables: {
          draft,
          locale,
          acceptLanguage,
          country,
          currency
        },
        fetchPolicy: 'no-cache'
      });
    },
    customerUpdateMe: async (context, currentUser, updatedUserData) => {
      const actions = getCustomerUpdateActions(updatedUserData);

      const updateResponse = await clientMutate(context, {
        mutation: customerUpdateMeQuery,
        variables: {
          version: currentUser.version,
          actions
        },
        fetchPolicy: 'no-cache'
      });

      return updateResponse.data;
    },
    getCategory: async (context, params, customQuery) => {
      const acceptLanguage = [params.acceptLanguage];
      const defaultVariables = {
        where: buildCategoryWhere({
          ...context.config,
          acceptLanguage
        }, params),
        limit: params.limit,
        offset: params.offset,
        acceptLanguage,
        fetchSiblings: params.fetchSiblings
      };

      const { categories } = context.extendQuery(customQuery,
        {
          categories: categoriesListingQuery[CUSTOM_QUERIES.CATEGORIES.LISTING]({
            variables: defaultVariables
          })
        }
      );

      const request = await clientQuery(context, {
        query: categories.query,
        variables: categories.variables
      });

      return request;
    },
    getProduct,
    getComplementaryProductsBySku,
    getOrders,
    getShippingMethodsWithoutId: async (context, params = {}, customQuery) => {
      let graphQLErrors, networkError;
      const { acceptLanguage } = context.config;
      const defaultVariables = {
        acceptLanguage,
        ...params
      };
      const { shippingMethods } = context.extendQuery(customQuery, {
        shippingMethods: shippingMethodsQuery[CUSTOM_QUERIES.SHIPPING_METHODS.shippingMethods]({
          variables: defaultVariables
        })
      });
      try {
        return await clientQuery(context, {
          query: shippingMethods.query,
          variables: shippingMethods.variables,
          fetchPolicy: 'no-cache'
        });
      } catch (error) {
        throw ((graphQLErrors = error.graphQLErrors) === null || graphQLErrors?.[0]) ||
          ((networkError = error.networkError) === null || networkError?.result) ||
          error;
      }
    },
    getVolumeDiscounts
  }
};
