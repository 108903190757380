import { useUiNavigationStore } from '~/composables';
import { UiNotification } from '~/types/components/Notification/UiNotification';

const useUiNotification = () => {
  const { uiNotifications, addUiNotification, removeUiNotification } = useUiNavigationStore();

  const send = (notification: UiNotification) => {
    const { id } = notification;

    const notificationDuration = notification.duration || 3000;
    const date = notification.date || new Date().toTimeString().split(' ')[0];

    const dismiss = () => {
      removeUiNotification(id);
    };

    const newNotification: UiNotification = {
      ...notification,
      id,
      date,
      dismiss
    };
    const checkIfNotificationExist = uiNotifications.value.find(el => el.id === id);
    if (!checkIfNotificationExist) {
      addUiNotification(newNotification);
    }

    if (notification.dismissible) {
      setTimeout(dismiss, notificationDuration);
    }
  };

  return {
    send
  };
};

export default useUiNotification;
