import { TagProduct } from '~/types/integrations/tagManager/TagProduct';
import { EcommerceViewProduct } from '~/types/integrations/tagManager/TagViewProduct';

export class ViewProductTags {
  event: string;
  ecommerce: EcommerceViewProduct = {
    detail: {
      actionField: {
        list: ''
      },
      products: []
    }
  }

  constructor (eventName: string) {
    this.event = eventName;
  }

  assignProductValues (productTags: TagProduct) {
    this.ecommerce.detail.products.push(productTags);
  }

  assignActionField (parentCategory: string) {
    this.ecommerce.detail.actionField.list = parentCategory;
  }
}
