import { ApiParams } from '~/utils/ct';
import { useIntegrations } from '~/composables';

export function useCategoryApi() {
  const { $ct } = useIntegrations({ vsfCt: true });

  const search = async ({ customQuery, ...searchParams }: ApiParams) => {
    const response = await $ct.api.getCategory(searchParams, customQuery);

    return response.data.categories.results;
  };

  return {
    search
  };
}
