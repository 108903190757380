






























import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { LinkType } from '~/types/components/Link/LinkType';
import SimpleCarousel from '~/components/molecules/SimpleCarousel/SimpleCarousel.vue';
import { RELATED_PRODUCTS_CONFIGURATION } from '~/constants/simpleCarousel';
import { useCheckoutTagManager, useIntegrations, useRecommendationEvent } from '~/composables';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import ProductAnchorTitle from '~/components/atoms/Product/ProductAnchorTitle/ProductAnchorTitle.vue';
import { getLastProductWithBreadcrumbs } from '~/helpers/getLastProductWithBreadcrumbs';
import BloomreachProductCart
  from '~/components/molecules/Product/Bloomreach/BloomreachProductCart/BloomreachProductCart.vue';
import { EVENT_SOURCE } from '~/constants/googleTagManager';
import { RECOMMENDATION_ACTIONS } from '~/constants/recommendationRequest';
import BloomreachEnvironmentWarning from
  '~/components/organisms/Product/Bloomreach/BloomreachEnvironmentWarning/BloomreachEnvironmentWarning.vue';
import { BLOOMREACH_TYPE_NAME } from '~/types/localization/localizationHelperTypes';

export default defineComponent({
  name: 'BloomreachCartRecommendations',
  components: {
    BloomreachProductCart,
    SimpleCarousel,
    ProductAnchorTitle,
    BloomreachEnvironmentWarning
  },
  setup() {
    const { $bloomreach } = useIntegrations();
    const { products, loadProducts } = useCheckoutTagManager();
    const bloomreachProducts = ref<BloomreachProduct[] | null>(null);

    const { triggerCiaRecommendationEvent, triggerGtmRecommendationEvent } =
      useRecommendationEvent({
        recommendationType: BLOOMREACH_TYPE_NAME.CART_RECOMMENDATION
      });
    onMounted(async () => {
      await loadProducts();
      bloomreachProducts.value = await $bloomreach.getCartRecommendations(
        getLastProductWithBreadcrumbs(products.value)
      );
      if (bloomreachProducts.value) {
        triggerCiaRecommendationEvent(RECOMMENDATION_ACTIONS.SHOW);
        triggerGtmRecommendationEvent({
          suggestedProducts: bloomreachProducts.value
        });
      }
    });

    return {
      bloomreachProducts,
      LinkType,
      RELATED_PRODUCTS_CONFIGURATION,
      EVENT_SOURCE,
      triggerCiaRecommendationEvent
    };
  }
});
