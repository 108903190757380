
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import type { Ref } from '@vue/composition-api';
import { computed } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import { productGetters } from '~/utils/ct';
import { useShippingMethods, useI18n } from '~/composables';
import getDeliveryDate from '~/helpers/getDeliveryDate';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import {
  isParcelFeeApplying,
  getShippingRates,
  getShippingFeeDetails,
  getProductShippingMethod
} from '~/helpers/product/getProductShippingDetails';
import { getProductPriceAfterDiscounts } from '~/helpers/product/getPriceInfo';

const useProductShipping = (product: Ref<ProductVariant>) => {
  const { loadMethodsWithoutId, methodsWithoutId } = useShippingMethods();
  const { languageAndCountry, countryCode } = useI18n();

  const priceAfterDiscounts = computed(() => getProductPriceAfterDiscounts(product.value));
  const isFreightFeeApplied = computed(() => productGetters.getAttributes(
    product.value, [PRODUCT_ATTRIBUTES.IS_PACKAGE_TYPE_FREIGHT])[PRODUCT_ATTRIBUTES.IS_PACKAGE_TYPE_FREIGHT]);
  const productShippingMethod = computed(() =>
    getProductShippingMethod(methodsWithoutId.value, !!isFreightFeeApplied.value)
  );

  const shippingFeeDetails = computed(() => {
    const shippingRate = getShippingRates(countryCode.value, productShippingMethod.value);
    return getShippingFeeDetails(shippingRate);
  });

  const isParcelFeeApplied = computed(() => isParcelFeeApplying(shippingFeeDetails.value, priceAfterDiscounts.value));

  const deliveryDate = computed(() => getDeliveryDate(languageAndCountry.value, productShippingMethod.value));

  onSSR(async () => {
    await loadMethodsWithoutId();
  });

  return {
    isFreightFeeApplied,
    isParcelFeeApplied,
    shippingFeeDetails,
    deliveryDate
  };
};

export default useProductShipping;
