import { computed } from '@nuxtjs/composition-api';
import { useShipping } from '@vsf-enterprise/commercetools';
import type { Address } from '@vsf-enterprise/commercetools-types';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  useCheckout
} from '~/composables';
import {
  ShippingDetailsMutations
} from '~/types/vuex/ShippingDetails';
import { MODULE_NAMES } from '~/types/vuex/Vuex';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.SHIPPING_DETAILS_MODULE_NAME,
    ShippingDetailsMutations,
    {},
    {}
  >(MODULE_NAMES.SHIPPING_DETAILS_MODULE_NAME);

  const {
    shipping: address
  } = useShipping();

  const { defaultAddress } = useCheckout();

  store.mutations.setShippingDetails(address.value || defaultAddress);

  const shippingDetails = computed(() => store.state.shippingDetails);

  const setShippingDetailsMutation = (shippingDetails: Partial<Address>) => {
    const addressToSet = shippingDetails || address.value || defaultAddress;
    store.mutations.setShippingDetails(addressToSet);
  };

  return {
    shippingDetails,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
    setShippingDetailsMutation
  };
}
