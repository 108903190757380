import { Review } from '~/utils/ct';

type ReviewCustomProperties = {
  name: string,
  value: string
}

export type CustomFieldsKeys =
  'reviewCreatedAt' |
  'customerCareResponseText' |
  'reviewSource' |
  'titleTranslations' |
  'textTranslations' |
  'answerTranslations'

type customReviewFields = {
    custom?: Record<CustomFieldsKeys, ReviewCustomProperties[]>
}

export type ProductReview = Review & customReviewFields

export interface ProductRating {
  count: Maybe<number>,
  averageRating: Maybe<number>
}

export const ProductReviewError = {
  purchaseNotFound: 'You haven\'t purchased this particular SKU',
  reviewAlreadySubmitted: 'You have already submitted review for your purchase for SKU'
} as const;
