const { buildProductWhere } = require('../product');
const filterOutIrrelevantLanguages = require('../filterOutIrrelevantLanguages');
const localizationDefinitions = require('../../../../i18n/localizationDefinitions.ts').default;
const { clientQuery } = require('../queries');
const { CUSTOM_QUERIES } = require('../../../../constants/customQueries.js');
const productPageQuery = require('../../queries/product/productPageQuery');
const handleCtApiError = require('../handleCtApiError');

/**
 * @param {Context} context
 * @param {*} params
 * @param {Maybe<CustomQuery>} customQuery
 */
const getProduct = async (context, params, customQuery) => {
  const { locale, currency, country, channel } = context.config;
  const acceptLanguage = localizationDefinitions.getByCountryCode(country)
    .getAcceptedLanguages(params.languagePrefix);

  const defaultVariables = {
    where: buildProductWhere(
      { ...context.config, acceptLanguage }, params
    ),
    skus: params.skus,
    limit: params.limit,
    offset: params.offset,
    locale: params.languagePrefix || locale,
    acceptLanguage,
    currency,
    country,
    ...(channel && { channelId: channel })
  };

  const { products } = context.extendQuery(customQuery, {
    products: productPageQuery[CUSTOM_QUERIES.PRODUCT_SEARCH.mainProductQuery.products]({
      variables: defaultVariables
    })
  });

  try {
    const request = await clientQuery(context, {
      query: products.query,
      variables: products.variables
    });
    return filterOutIrrelevantLanguages(request, acceptLanguage);
  } catch (error) {
    handleCtApiError(error);
  }
};

module.exports = getProduct;
