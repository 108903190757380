const { APPLICATION_ENVIRONMENT } = require('../../constants/env');
const { getEnvironment } = require('../env/getEnvironment');
const { getEnvironmental } = require('../env/getEnvironmental');
const { DEV_PREFIX } = require('../../constants/http');
const { PREPROD_NAMESPACE, POD_NAMESPACE_INDICATOR } = require('../../constants/domains');
const { StringBoolean } = require('../../types/StringBoolean');
/**
 * @returns boolean
 */
const isProduction = () => {
  return getEnvironment() === APPLICATION_ENVIRONMENT.PRODUCTION;
};

/**
 * @returns boolean
 */
const isLocal = () => {
  return getEnvironment() === APPLICATION_ENVIRONMENT.LOCAL;
};

/**
 * @returns boolean
 */
const isPreProduction = () => {
  const isAccordingToEnvironmental = getEnvironmental(POD_NAMESPACE_INDICATOR) === PREPROD_NAMESPACE;
  const isAccordingToWindow = typeof window !== 'undefined' && window?.location?.hostname?.startsWith(DEV_PREFIX);
  return isAccordingToEnvironmental || isAccordingToWindow;
};

const isSeparateMiddleware = () => !isLocal() || process.env.SEPARATE_LOCAL_MIDDLEWARE === StringBoolean.true;

module.exports = {
  isSeparateMiddleware,
  isProduction,
  isLocal,
  isPreProduction
};
