import type { IVueI18n } from 'vue-i18n';
import {
  triggerPromotionTagsWrapper,
  triggerRemarketingTagsWrapper,
  triggerPurchaseTagsWrapper,
  triggerPayNowTagsWrapper,
  triggerViewProductTagsWrapper,
  triggerAddToCartTagsWrapper,
  triggerQuantityChangeTagsWrapper,
  triggerPageViewWrapper,
  triggerViewItemListWrapper,
  triggerRemoveFromCartTagsWrapper,
  triggerViewHitsTagsWrapper,
  triggerClickHitTagsWrapper,
  triggerConversionTagsWrapper,
  triggerClickAccessoryTagsWrapper,
  triggerClickParentProductTagsWrapper,
  triggerSearchResultsTagsWrapper,
  triggerViewAccessoriesTagsWrapper,
  triggerViewComplementaryTagsWrapper,
  triggerClickComplementaryTagsWrapper,
  triggerClickProductBoxTagsWrapper,
  triggerClickBreadcrumbsWrapper,
  triggerBeginCheckoutTagsWrapper,
  triggerRecommendationsShownTagsWrapper,
  triggerViewParentProductsTagsWrapper,
  triggerClickAlternativeProductWrapper,
  triggerClickFilterWrapper,
  triggerClickItemBundleBuilderWrapper,
  triggerAddToCartBundleBuilderWrapper,
  triggerViewBundleBuilderWrapper,
  triggerClearSingleFiltersParameterWrapper,
  triggerClearAllFiltersParametersWrapper
} from './events';
import { TagManagerIntegration } from '~/types/integrations/tagManager/TagManagerIntegration';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { i18nToLanguageAndCountry } from '~/helpers/locales/i18nCurrentDetails';

export default ($gtm: Gtm, i18n: IVueI18n, currency: string): TagManagerIntegration => {
  const languageAndCountry = i18nToLanguageAndCountry(i18n);
  const $tagManagerIntegration: TagManagerIntegration = {
    events: {
      triggerPromotionTags: triggerPromotionTagsWrapper($gtm),
      triggerRemarketingTags: triggerRemarketingTagsWrapper($gtm),
      triggerPurchaseTags: triggerPurchaseTagsWrapper($gtm, languageAndCountry, currency),
      triggerPayNowTags: triggerPayNowTagsWrapper($gtm, languageAndCountry, currency),
      triggerViewProductTags: triggerViewProductTagsWrapper($gtm, languageAndCountry, currency),
      triggerAddToCartTags: triggerAddToCartTagsWrapper($gtm, languageAndCountry, currency),
      triggerQuantityChangeTags: triggerQuantityChangeTagsWrapper($gtm),
      triggerPageView: triggerPageViewWrapper($gtm),
      triggerViewItemList: triggerViewItemListWrapper($gtm, languageAndCountry),
      triggerRemoveFromCartTags: triggerRemoveFromCartTagsWrapper($gtm, languageAndCountry, currency),
      triggerViewHitsEvent: triggerViewHitsTagsWrapper($gtm),
      triggerClickHitEvent: triggerClickHitTagsWrapper($gtm),
      triggerConversionEvent: triggerConversionTagsWrapper($gtm),
      triggerClickAccessoryTags: triggerClickAccessoryTagsWrapper($gtm),
      triggerClickParentProductTags: triggerClickParentProductTagsWrapper($gtm),
      triggerViewAccessoriesTags: triggerViewAccessoriesTagsWrapper($gtm),
      triggerViewParentProductsTags: triggerViewParentProductsTagsWrapper($gtm),
      triggerSearchResultsTags: triggerSearchResultsTagsWrapper($gtm),
      triggerClickProductBoxTags: triggerClickProductBoxTagsWrapper($gtm),
      triggerClickBreadcrumbsTags: triggerClickBreadcrumbsWrapper($gtm),
      triggerBeginCheckoutTags: triggerBeginCheckoutTagsWrapper($gtm, languageAndCountry, currency),
      triggerClickItemBundleBuilder: triggerClickItemBundleBuilderWrapper($gtm),
      triggerRecommendationsShownTags: triggerRecommendationsShownTagsWrapper($gtm),
      triggerClickAlternativeProduct: triggerClickAlternativeProductWrapper($gtm),
      triggerClickFilter: triggerClickFilterWrapper($gtm),
      triggerAddToCartBundleBuilder: triggerAddToCartBundleBuilderWrapper($gtm),
      triggerViewBundleBuilder: triggerViewBundleBuilderWrapper($gtm),
      triggerViewComplementaryTags: triggerViewComplementaryTagsWrapper($gtm),
      triggerClickComplementaryTags: triggerClickComplementaryTagsWrapper($gtm),
      triggerClearSingleFiltersParameter: triggerClearSingleFiltersParameterWrapper($gtm),
      triggerClearAllFiltersParameters: triggerClearAllFiltersParametersWrapper($gtm)
    }
  };

  return $tagManagerIntegration;
};
