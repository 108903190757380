const { gql } = require('graphql-tag');
const ThankYouPageOrder = require('../fragments/order/thankYouPageOrder').fragment;
const { CUSTOM_QUERIES } = require('#~/constants/customQueries.js');

module.exports = {
  // fetches the order for Thank You page
  // extends: node_modules/@vue-storefront/commercetools-api/src/api/getOrders/defaultQuery.ts
  // new fields added in ThankYouPageOrder fragment: discountCodes (with cartDiscounts), taxedPrice and shippingInfo
  [CUSTOM_QUERIES.THANK_YOU_PAGE_ORDER.getMyOrders]: (params) => {
    const variables = params.variables;

    return {
      query: gql`
        ${ThankYouPageOrder}

        query getThankYouPageOrder($where: String, $sort: [String!], $limit: Int, $offset: Int, $locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!) {
          me {
            orders(where: $where, sort: $sort, limit: $limit, offset: $offset) {
              results {
                ...ThankYouPageOrder
              }
              total
              offset
              count
            }
          }
        }
      `,
      variables
    };
  }
};
