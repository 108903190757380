



















import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { productGetters } from '~/utils/ct';
import { useProductPrice, useI18n } from '~/composables';
import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { extractLocalisedAttributes } from '~/helpers/product/extractLocalisedAttributes';
import MediumProductCard from '~/components/molecules/Product/MediumProductCard/MediumProductCard.vue';
import { EVENT_SOURCE } from '~/constants/googleTagManager';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';

export default defineComponent({
  name: 'PdpComplementaryProductCard',
  components: {
    MediumProductCard
  },
  props: {
    product: {
      type: Object as PropType<ProductVariantExtended>,
      required: true
    }
  },
  setup(props) {
    const { product } = toRefs(props);

    const { getPriceInformation } = useProductPrice();

    const { languageAndCountry } = useI18n();

    const extractedAttributes = computed(() =>
      extractLocalisedAttributes(
        product.value,
        [
          PRODUCT_ATTRIBUTES.BRAND,
          PRODUCT_ATTRIBUTES.TAGS,
          PRODUCT_ATTRIBUTES.ENERGY_RATING,
          PRODUCT_ATTRIBUTES.STANDARD_PRICE
        ],
        languageAndCountry.value
      )
    );

    const productCardInfo = computed(() => {
      const { productPrice, discountedPrice } =
        getPriceInformation(product, extractedAttributes);

      return {
        sku: product.value?.sku,
        currency: product.value?.price.value.currencyCode,
        name: product.value?._name,
        image: product.value?.images?.[0]?.url,
        price: productPrice.value,
        discountedPrice: discountedPrice.value,
        link: `/${product.value._slug}`,
        rating: productGetters.getAverageRating(product.value),
        reviewsCount: productGetters.getTotalReviews(product.value),
        categories: product.value._categories?.reduce((acc, category) => {
          acc[category.slug] = category.name;
          return acc;
        }) || ''
      };
    });

    return {
      productCardInfo,
      EVENT_SOURCE,
      extractedAttributes
    };
  }
});
