import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import { centAmountToNormalPrice } from '~/helpers/cart/getTotalPricesModule';
import getProductAttribute from '~/helpers/product/getProductAttribute/getProductAttribute';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';

export const getDiscountedPrice = (product: ProductVariant): number => {
  return centAmountToNormalPrice(product?.price?.discounted?.value?.centAmount);
};
export const getRegularPrice = (product: ProductVariant): number => {
  return centAmountToNormalPrice(product?.price?.value?.centAmount);
};

export const getProductPriceAfterDiscounts = (product: ProductVariant): number => {
  return getDiscountedPrice(product) || getRegularPrice(product) || 0;
};

export const extractStandardPrice = (attributesLocalized?: ProductProcessedAttributes): number|null => {
  return attributesLocalized?.standardPrice
    ? centAmountToNormalPrice(JSON.parse(attributesLocalized.standardPrice)?.centAmount)
    : null;
};

export const extractStandardPriceFromProduct = (product: ProductVariant, languageAndCountry: string): number|null => {
  const standardPriceJson = getProductAttribute(product, PRODUCT_ATTRIBUTES.STANDARD_PRICE, languageAndCountry);
  const standardPriceDetails = standardPriceJson ? JSON.parse(standardPriceJson) : null;
  return centAmountToNormalPrice(standardPriceDetails?.centAmount) || null;
};

export const getProductPriceBeforeDiscounts = (
  product: ProductVariant,
  params?: {
    attributesLocalized?: ProductProcessedAttributes,
    languageAndCountry?: string
  }
): number | null => {
  const discountedPrice = getDiscountedPrice(product);
  if (discountedPrice) {
    return getRegularPrice(product);
  }
  if (params?.attributesLocalized) {
    return extractStandardPrice(params.attributesLocalized);
  }
  if (params?.languageAndCountry) {
    return extractStandardPriceFromProduct(product, params.languageAndCountry);
  }

  return null;
};
