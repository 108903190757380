import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import getProductAttribute from '~/helpers/product/getProductAttribute/getProductAttribute';
import { isBWareProduct } from '~/helpers/product/productCondition';

export const getProductComparisonFilter = (product: ProductVariant): string | undefined => {
  const sku = product.sku;
  const isBware = isBWareProduct(sku);
  const family = getProductAttribute(product, PRODUCT_ATTRIBUTES.FAMILY);

  if (!sku || !family) {
    return;
  }

  return ` and masterData(current(masterVariant(
    attributes((name="family" and value="${family}")) and
    attributes((name="isBware" and value=${isBware})) and
    sku != "${sku}"
  )))`;
};
