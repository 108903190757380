import { sha256 } from 'js-sha256';
import type { Cart, Order, ProductVariant } from '@vsf-enterprise/commercetools-types';
import { cartGetters } from '@vsf-enterprise/commercetools';
import { productGetters } from '~/utils/ct';
import { PurchaseTags } from '~/plugins/integrations/tagManager/helpers/PurchaseTags';
import { prepareProductTags } from '~/plugins/integrations/tagManager/helpers/transformers';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { getShippingPrice, getTotalTaxPrice } from '~/helpers/cart/getTotalPrices';
import { MappedDiscount } from '~/types/discounts';
import { getFirstDiscountCode } from '~/helpers/cart/cartDiscountGetter';
import { getTotalGrossPrice } from '~/helpers/cart/getTotalPricesModule';
import { PurchaseUserInfo } from '~/types/integrations/tagManager/PurchaseTypes';

export const triggerPurchaseTagsWrapper = ($gtm: Gtm, languageAndCountry: string, currency: string) => {
  return (
    cartOrOrder: Cart|Order,
    products: ProductVariant[],
    orderNumber: Order['orderNumber'],
    paymentType?: string,
    paymentName?: string,
    purchaseUserInfo?: PurchaseUserInfo
  ) => {
    const coupon: MappedDiscount['code'] = getFirstDiscountCode(cartOrOrder);
    const purchaseTags = new PurchaseTags();

    if (products?.length) {
      const productTags = products.map(product => {
        return prepareProductTags(
          product,
          {
            currency,
            quantity: cartOrOrder.lineItems.find(item =>
              cartGetters.getItemSku(item) === productGetters.getSku(product))?.quantity,
            languageAndCountry
          });
      });
      purchaseTags.assignProductValues(productTags);
    }

    purchaseTags.assignOrderInformation({
      transaction_id: orderNumber,
      value: getTotalGrossPrice(cartOrOrder),
      tax: getTotalTaxPrice(cartOrOrder),
      shipping: getShippingPrice(cartOrOrder),
      payment_type: paymentType,
      payment_name: paymentName,
      currency,
      coupon
    });

    if (purchaseUserInfo !== undefined) {
      const { email, phone } = purchaseUserInfo;
      const hashedEmail = email ? sha256(email) : undefined;
      const hashedPhone = phone ? sha256(phone) : undefined;

      purchaseTags.assignAttributes({
        customer_email: email,
        customer_hashed_email: hashedEmail,
        customer_hashed_phone: hashedPhone,
        // for lt_transactions, ltv, account_age and account_created_date we're abandoning it for now - we'll get back to it after delivering account management
        lt_transactions: undefined,
        ltv: undefined,
        account_age: undefined,
        account_created_date: undefined,
        crm_status: 0
      });
    }

    $gtm.push({ ecommerce: null });
    $gtm.push(purchaseTags);
  };
};
