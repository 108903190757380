import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { productGetters } from '~/utils/ct';
import getAttributeLocales from '~/helpers/product/getAttributeLocales';

export default (product: ProductVariant, attributeName: string, languageAndCountry?: string): string => {
  if (!product?.attributesRaw) return '';
  const attributes = productGetters.getAttributes(product, [attributeName]);
  let attributeValue = attributes?.[attributeName];
  if (typeof attributeValue === 'object' && languageAndCountry) {
    attributeValue = (languageAndCountry in attributeValue)
      ? getAttributeLocales(attributes, languageAndCountry)?.[attributeName]
      : null;
  }
  return attributeValue ? attributeValue?.toString() : '';
};
