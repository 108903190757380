



















import { defineComponent, ref, toRefs, watch, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { useClickFiltersEvent } from '~/composables';
import RangeInput from '~/components/molecules/Search/RangeInput.vue';
import RangeSlider from '~/components/molecules/Search/RangeSlider.vue';
import type { ValueRange, ValueRangeOptional } from '~/types/ValueRange';
import { EVENT_SOURCE, FILTERS_INTERACTIONS } from '~/constants/googleTagManager';

export default defineComponent({
  name: 'PricePickerInner',
  components: {
    RangeInput,
    RangeSlider
  },
  props: {
    currentRefinement: {
      type: Object as PropType<ValueRangeOptional>,
      required: true
    },
    maxRange: {
      type: Object as PropType<ValueRange>,
      required: true
    },
    canRefine: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { currentRefinement } = toRefs(props);
    const localRange = ref(getDefaults());
    const { i18n } = useVSFContext();
    const { callClickFilterEvent } = useClickFiltersEvent();

    const localisedName = computed(() => i18n.t('Price'));

    watch(currentRefinement, () => {
      localRange.value = getDefaults();
    });

    function getDefaults(): ValueRange {
      let min = props.maxRange.min;
      const currentMin = currentRefinement.value.min;
      if (currentMin && currentMin > min) {
        min = currentMin;
      }

      let max = props.maxRange.max;
      const currentMax = currentRefinement.value.max;
      if (currentMax && currentMax < max) {
        max = currentMax;
      }

      return { min, max };
    }

    function setValues(range: ValueRange): void {
      localRange.value = range;
    }

    function refineRange(range: ValueRange): void {
      emit('refine', range);
    }

    function onSliderInput(range: ValueRange): void {
      setValues(range);
    }

    function handleRefinement(range: ValueRange): void {
      setValues(range);
      refineRange(range);

      callClickFilterEvent({
        filterInteraction: FILTERS_INTERACTIONS.FILTER_SELECT,
        filterSection: EVENT_SOURCE.PRICE_RANGE,
        filterName: localisedName.value,
        filterValue: { ...localRange.value }
      });
    }

    return { localRange, onSliderInput, handleRefinement };
  }
});
