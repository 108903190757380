import { EVENT_CLICK_COMPLEMENTARY } from '~/constants/googleTagManager';

/* eslint-disable camelcase */
export class ClickComplementaryTags {
  event = EVENT_CLICK_COMPLEMENTARY;
  items: {
    complementary_id: string;
  };

  constructor(id: string) {
    this.items = {
      complementary_id: id
    };
  }
}
