













import { defineComponent } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { AisClearRefinements } from 'vue-instantsearch';
import { useIntegrations, usePageEventLocation, useCategories } from '~/composables';
import { EVENT_SOURCE } from '~/constants/googleTagManager';

export default defineComponent({
  name: 'ClearRefinements',
  components: {
    AisClearRefinements
  },
  setup() {
    const { route } = useVSFContext();
    const { $tagManager } = useIntegrations();
    const { getCurrentPageType } = usePageEventLocation();
    const {
      getters: {
        currentCategoryName
      }
    } = useCategories();
    const pageType = getCurrentPageType();
    const isCategoryListingPage = pageType === EVENT_SOURCE.CATEGORY_LISTING;
    const categoryName = isCategoryListingPage ? currentCategoryName.value : '';
    const categoryPath = isCategoryListingPage ? route.value.params.slug : '';

    const resetFilters = (refine: () => void) => {
      $tagManager.events.triggerClearAllFiltersParameters({
        categoryPath,
        categoryName,
        listingType: pageType
      });
      refine();
    };

    return {
      resetFilters
    };
  }
});
