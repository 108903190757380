import { sharedRef, Logger } from '@vue-storefront/core';
import type { Customer } from '@vsf-enterprise/commercetools-types';
import { userGetters } from '~/utils/ct';
import { useIntegrations } from '~/composables';

export const useMyOrders = () => {
  const { $myOrders } = useIntegrations();
  const urlWithToken = sharedRef('', 'myOrderUrl');
  const setUrlWithToken = async (user: Customer) => {
    try {
      const response = await $myOrders.getUrlWithToken(userGetters.getEmailAddress(user));
      urlWithToken.value = String(response.data);
    } catch (e) {
      Logger.error(`Error during getting url with token, message:  ${e}`);
    }
  };

  return {
    urlWithToken,
    setUrlWithToken
  };
};
