const { gql } = require('graphql-tag');

const DefaultMoney = require('../../fragments/money/defaultMoney').fragment;

module.exports = {
  fragment: gql`
    ${DefaultMoney}

    fragment DefaultTaxedPrice on TaxedPrice {
      totalNet {
        ...DefaultMoney
      }
      totalGross {
        ...DefaultMoney
      }
      taxPortions {
        rate
        amount {
          ...DefaultMoney
        }
      }
    }
  `
};
