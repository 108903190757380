import { ApiParams } from '~/utils/ct';
import { useIntegrations } from '~/composables';
import { useProductApi } from '~/utils/ct/composables/api/useProductApi';
import { catchApiErrors } from '~/utils/ct/composables/helpers/apiHelpers';

export function useReviewApi() {
  const { $ct } = useIntegrations({ vsfCt: true });
  const { search: apiSearch } = useProductApi();

  const search = async ({ customQuery, ...searchParams }: ApiParams) => {
    const response = await $ct.api.getReview(searchParams, customQuery);
    catchApiErrors(response);
    const product = await apiSearch({ id: searchParams.productId, limit: 1 });
    catchApiErrors(product);
    return {
      ...response,
      ...product?.data?.products?.results[0]?.reviewRatingStatistics
    };
  };

  return {
    search
  };
}
